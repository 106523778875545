<template>
  <div class="centered">
    <el-image src="https://vahtihuonekortti.fi/img/vahtilogo.950d4b13.png"/>
    <h1 class="hidden-xs-only">Yksinkertaista terveydenhuollon rakentamisen vaatimustenhallintaa</h1>
    <h2 class="hidden-sm-and-up">Yksinkertaista terveydenhuollon rakentamisen vaatimustenhallintaa</h2>
    <h3>Vahti on järjestelmä, jota käytetään terveydenhuollon rakennushankkeissa. Vahti-järjestelmään kirjataan käyttäjiltä saatu kuvaus rakennettaviin tiloihin tulevasta toiminnasta ja sen tiloille asettamista vaatimuksista. Tehdyt kirjaukset toimivat suunnittelun lähtötietoina eri suunnittelualoille. Tietoja täydennetään tarvittaessa rakennushankkeen edetessä.</h3>
    <h3>Vahtia käytetty jo <span style="color: #67C23A">{{ hankeMaara }}</span> hankkeessa 13:lla hyvinvointialueella</h3>
    <p>
      <el-button type="primary" size="large" @click="$emit('otaYhteytta')">Ota yhteyttä</el-button>
    </p>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ComVahti',
  emits: ['otaYhteytta'],
  setup() {
    const hankeMaara = ref(0)

    const interval = window.setInterval(() => {
      if(hankeMaara.value >= 138) {
        window.clearInterval(interval)
        
        return
      }
      hankeMaara.value += 1
    }, 20)

    return {
      hankeMaara
    }

  },
})
</script>
