<template>
    <el-row justify="center">
        <el-col :span="24" class="yhteys-column">
            <h2>Ota yhteyttä</h2>
            <h3>Vahti -huonekorttijärjestelmän ylläpito, kehitys, koulutus, myynti ja konsultointi</h3>
            <h3>Datasund Oy, Jani Saine</h3>
            <h3>Sähköposti: jani.saine[ät]tietosund.fi</h3>
            <h3>Puhelin: +35850 355 5467</h3>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="8" class="logo-column">
            <div class="logo-container">
                <el-image :src="ssty" fit="contain" style="width: 200px;"></el-image>
            </div>
        </el-col>
        <el-col :span="8" class="logo-column">
            <div class="logo-container">
                <el-image :src="vahti" fit="contain" style="width: 200px;"></el-image>
            </div>
        </el-col>
        <el-col :span="8" class="logo-column">
            <div class="logo-container">
                <el-image :src="datasund" fit="contain" style="width: 200px;"></el-image>
            </div>
        </el-col>
    </el-row>
    
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const datasund = require('@/assets/images/datasund.png')
        const ssty = require('@/assets/images/ssty.png')
        const vahti = require('@/assets/images/vahti.png')

        return {
            datasund,
            ssty,
            vahti
        }
    },
})
</script>

<style scoped>
    .yhteys-column {
        padding: 30px;
        height: 300px;
        text-align: center;
    }
    .logo-column {
        padding: 30px;
        height: 150px;
    }
    .logo-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
    }
</style>
