<template>
    <el-row justify="center" :gutter="20">
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="planning"/>
                </div>
                <h3>Vaatimustenhallinta</h3>
                <p>Vahti soveltuu niin pienen kuin isonkin terveydenhuollon rakentamishankkeen vaatimustenhallintaan.</p>
                <p>Vahti mahdollistaa vaatimustiedon keräämisen tilojen käyttäjiltä ja tiedon hallinnoimisen.</p>
            </el-card>
        </el-col>
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="check"/>
                </div>
                <h3>Vakioitu nimikkeistö</h3>
                <p>Vahdin nimikkeistö on vakioitu palvelemaan terveydenhuollon rakentamista. </p>
                <p>Vahdissa tilanimikkeistö on yhtenäistetty hyvinvointialueiden käytössä olevien tilanimikkeiden pohjalta ja suunnittelualakohtainen sisältö noudattaa vakiintunutta nimikkeistöä.</p>
            </el-card>
        </el-col>
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="change"/>
                </div>
                <h3>Muutosloki</h3>
                <p>Vahdin avulla hallitset helposti tilojen rakentamishankkeen aikana muuttuvia vaatimuksia. Muutosloki-ominaisuudella on helppo tarkastella muutoksen kohdetta, tekijää ja ajankohtaa.</p>
            </el-card>
        </el-col>
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="convenient"/>
                </div>
                <h3>Helppokäyttöinen</h3>
                <p>Kehittämisen lähtökohtana on ollut helppokäyttöisyys. Vahti on suunniteltu siten, että käyttäjä voi keskittyä olennaiseen eli vaatimusten kirjaamiseen ja muokkaamiseen.</p>
            </el-card>
        </el-col>
    </el-row>
    <el-row justify="center" :gutter="20">
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="report"/>
                </div>
                <h3>Raportointi</h3>
                <p>Vahti mahdollistaa toiminnan tiloille asettamien vaatimustietojen sisällön ja muutosten laaja-alaisen raportoinnin. Halutut tiedot voidaan suodattaa Vahdista koontiraporttiin PDF- tai Excel-muotoon.</p>
            </el-card>
        </el-col>
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="lightHouse"/>
                </div>
                <h3>Ohjaa ja opastaa</h3>
                <p>Vahti ohjaa ja opastaa käyttäjää vaatimusten kirjaamisessa.</p>
                <p>Käyttäjälle tarjotaan kirjaamista helpottavia ohjeita käyttäjän valintojen mukaan.</p>    
            </el-card>
        </el-col>
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="handShake"/>
                </div>
                <h3>Käyttäjältä käyttäjälle</h3>
                <p>Vahdin käyttäjät ovat olleet mukana kehittämässä järjestelmää. Näin on varmistettu, että järjestelmä palvelee parhaalla mahdollisella tavalla sen käyttötarkoitusta.</p>
            </el-card>
        </el-col>
        <el-col :span="22" :lg="5" :md="11" :sm="11" class="ominaisuus-col">
            <el-card class="ominaisuus-card">
                <div class="image-container">
                    <el-image :src="priceTag"/>
                </div>
                <h3>Kustannustehokas</h3>
                <p>Vahti on Suomen Sairaalatekniikan yhdistyksen omistama järjestelmä. Tämä takaa järjestelmän käyttömaksujen kohtuullisuuden.</p>
                <p>Järjestelmän käytöstä kerätyt käyttömaksut käytetään täysimääräisesti järjestelmän ylläpito- ja kehitystöhön.</p>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const priceTag = require('../assets/images/price-tag.png')
        const handShake = require('../assets/images/intermediary.png')
        const lightHouse = require('../assets/images/lighthouse.png')
        const report = require('../assets/images/report.png')
        const convenient = require('../assets/images/convenient.png')
        const change = require('../assets/images/change.png')
        const check = require('../assets/images/check.png')
        const planning = require('../assets/images/planning.png')

        return {
            priceTag,
            handShake,
            lightHouse,
            report,
            convenient,
            change,
            check,
            planning
        }
    },
})
</script>

<style scoped>
    .image {
        width: 100%;
        display: block;
        margin-bottom: 40px;
    }
    .ominaisuus-col {
        margin-top: 30px;
        margin-bottom: 30px;    
        text-align: center;
    }
    .ominaisuus-card {
        min-height: 510px;
    }
    .image-container {
        max-width: 200px;
        margin: 0 auto;
    }
</style>
