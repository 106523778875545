<template>
  <el-container>
    <el-header>
      <el-affix>
        <el-menu mode="horizontal" :default-active="activeIndex" :ellipsis="false">
          <el-menu-item class="hidden-xs-only" index="vahti" @click="scrollTo('vahti')">Vahti</el-menu-item>
          <el-menu-item class="hidden-xs-only" index="ominaisuudet" @click="scrollTo('ominaisuudet')">Ominaisuudet</el-menu-item>
          <el-menu-item class="hidden-xs-only" index="referenssit" @click="scrollTo('referenssit')">Referenssit</el-menu-item>
          <el-menu-item class="hidden-xs-only" index="otayhteytta" @click="scrollTo('otayhteytta')">Ota yhteyttä</el-menu-item>
          <div style="flex-grow: 1" />
          <el-menu-item index="kirjaudu" id="kirjaudu">
            <a href="https://vahtihuonekortti.fi/kirjaudu">
                <el-icon>
                    <SwitchButton />
                </el-icon>
                Kirjaudu Vahtiin
            </a>
        </el-menu-item>
        </el-menu>
      </el-affix>
    </el-header>
    <el-main>
      <div id="vahti">
        <ComVahti @ota-yhteytta="scrollTo('otayhteytta')"/>
      </div>
      <div id="ominaisuudet">
        <ComOminaisuudet/>
      </div>
      <div id="referenssit">
        <ComReferenssit />
      </div>
      <div id="otayhteytta">
        <ComOtayhteytta />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ComOminaisuudet from './components/ComOminaisuudet.vue'
import ComOtayhteytta from './components/ComOtayhteytta.vue'
import ComReferenssit from './components/ComReferenssit.vue'
import ComVahti from './components/Comvahti.vue'
import { SwitchButton } from '@element-plus/icons-vue'

import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    ComVahti,
    ComOminaisuudet,
    ComReferenssit,
    ComOtayhteytta,
    SwitchButton
  },
  setup() {
    const activeIndex = ref('vahti')

    const scrollTo = (id) => {
      const el = document.getElementById(id)
      const position = el.offsetTop - 70

      window.scrollTo({ top: position, behavior: 'smooth' })
    }

    onscroll = () => {
      const position = window.scrollY
      const vahti = document.getElementById('vahti')
      const ominaisuudet = document.getElementById('ominaisuudet')
      const referenssit = document.getElementById('referenssit')
      const otayhteytta = document.getElementById('otayhteytta')

      if(position > otayhteytta.offsetTop - 200) {
        activeIndex.value = 'otayhteytta'
      } else if(position > referenssit.offsetTop -250) {
        activeIndex.value = 'referenssit'
      } else if(position > ominaisuudet.offsetTop -120) {
        activeIndex.value = 'ominaisuudet'
      } else if(position > vahti.offsetTop) {
        activeIndex.value = 'vahti'
      }
    }

    return {
      scrollTo,
      activeIndex
    }
  }

})
</script>

<style>
body, button {
    font-family: Josefin Sans !important;
}
h1 {
    font-weight: 400;
    font-size: 48px;
}
h2 {
    font-weight: 400;
    font-size: 36px;
}
h3 {
    font-weight: 400;
    font-size: 24px;
}

#vahti {
    scroll-margin-bottom: 100px;
}

.centered {
    margin: auto;
    text-align: center;
}

#ominaisuudet {
    width: 100%;
    height: 100%;
    background-color: #f2f6fc;
}

#referenssit {
    width: 100%;
    height: 100%;
    background-color: #f2f6fc;
}

#otayhteytta {
    width: 100%;
    height: 450px;
}
#kirjaudu {
    background-color: #67C23A;
    color: white;
    cursor: pointer;
}
#kirjaudu a {
    text-decoration: none;
}
</style>